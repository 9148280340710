.page-wrapper{
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: #F7FAFF;
    height: 100%;
}

.page-wrapper body{
    background-color: #F7FAFF;
    color: #808000;
}

.page-wrapper img{
    width: 60%;
    display: block;
    margin: 50px auto;
}

.page-wrapper ul{
    padding-left: 0;
}

.article-head{
    font-size: 40px;
    text-align: center;
    font-family: "comfortaa";
}

.article-date{
    font-size: 20px;
    text-align: center;
    font-family: "comfortaa";
}

.article-body p{
    font-size: 20px;
    font-family: "poppins";
}

.page-wrapper h3{
    margin-top: 100px;
    font-family: "comfortaa";
}

.page-wrapper a{
    color: #808000;
}

.btn-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.page-btn{
    transition-duration: 0.3s;
    border: 2px solid #808000;
    font-family: 'comfortaa';
    font-size: 16px;
    border-radius: 25px;
    height: 50px;
    width: 200px;
    
    text-align:center;
    text-decoration: none;
    color: #808000;

    display: block;
    padding-top: 12px;
}

.page-btn:hover{
    cursor: pointer;
    background-color: #808000;
    color: #F7FAFF;
}

.btn__disabled{
    pointer-events: none;
    visibility: hidden;
}
.share-button {
    margin: 10px;
    cursor: pointer;
  }

@media screen and (max-width: 780px){
    .btn-wrapper{
        flex-direction: column;
    }

    .page-btn{
        margin: 10px auto;
    }
}
