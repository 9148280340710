body {
    background-color: #D9D9D9; /* Set your desired background color */
}

.portfolio-info-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    margin-bottom: 40px;
}

.portfolio-info-heading{
    color: #808000;
    font-family: Comfortaa;
    font-size: 20px;
}


.portfolio-info-content{
    color: #808000;
    font-family: Comfortaa;
    font-size: 25px;
    background-color: #F8FAFE;;
    border: 0.5px solid #808000;
    width: 320px;
    height: 50px;
    font-size: 20px;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    display: flex;
}

.portfolio-info-content-inactive{
    color: #808000;
    font-family: Comfortaa;
    text-align: center;
    font-size: 25px;
    background-color: #F8FAFE;
    /* background: rgba(255, 255, 255, 0.05); */
    border: 0.5px solid #808000;
    width: 320px;
    height: 50px;
    font-size: 20px;
    align-items: center;
    display: flex;
}

.portfolio-badge-wrapper{
    width: 168px;
    height: 200px;
    background-color: #F8FAFE;
    border-color: #808000;
    border-width: 0.5px;
    border-style: solid;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.portfolio-badge-img{
    margin: 0px !important;
}

.portfolio-badge-name{
    color: #808000;
    text-align: center;
    font-family: Comfortaa;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media screen and (max-width: 900px) {
    .portfolio-info-content {
        width: 285px;
        font-size: 18px;
    }   

    .portfolio-badge-name{
        font-size: 15px;
    }

    .portfolio-badge-wrapper{
        width: 150px;
    }
}

@media screen and (max-width: 650px) {
    .portfolio-info-content {
        width: 270px;
        font-size: 17px;
    }   
    .portfolio-badge-name{
        font-size: 15px;
    }

    .portfolio-badge-wrapper{
        width: 115px;
    }
}

@media screen and (max-width: 600px) {
    .portfolio-info-wrapper{
        flex-direction: column;
        align-items: center;
    }

    .portfolio-badge-wrapper{
        width: 200px;
    }

}
