
.articlesPage .news-title {
    text-align:center;
    padding-top:5px;
    padding-bottom:50px;
    font-family: "comfortaa";
    font-size: 35px;
    color: #808000;
    font-weight: bold;
    background-color: #f7faff;
    margin-top: -10px;
}
.articlesPage .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    background-color: #f7faff;
}
.articlesPage .ct-blog {
    margin-bottom: 30px;
}
.ct-blog .inner {
    background-color: #f7faff;
    padding: 10px;
    transition: all 0.2s ease-in-out 0s;
    cursor: pointer;
    height: 100%;
    
}
.ct-blog .inner:hover {
    background-color: #e2ebda;
}
.ct-blog .fauxcrop {
    height: 180px;
    overflow: hidden;
}
.ct-blog .fauxcrop img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
.ct-blog-content {
    display: table;
    padding: 30px 0 28px;
}
.ct-blog-content .ct-blog-date {
    border-right: 1px solid #95A5A6;
    display: table-cell;
    font-family: "poppins";
    font-size: 18px;
    padding: 0px 18px 0px 15px;
    text-align: center;
    text-decoration: none;
}
.ct-blog-content .ct-blog-date span {
    font-size: 16px;
    color: #808000;  
    font-weight: 700;
    display: block;
    line-height: 1;
    text-decoration: none;
}
.ct-blog-content .ct-blog-date strong {
    font-size: 25px;
    font-family: "comfortaa";
    color: #808000;  
    text-decoration: none;
}
.ct-blog-content .ct-blog-header {
    color: #808000;
    display: table-cell;
    font-family: "poppins";
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 1.1;
    padding: 0 20px;
    vertical-align: top;
    text-decoration: none;
}
.btn-news {
    color: #808000;
    border-style: none;
    background-color: #f7faff;
    font-family: "poppins";
    font-size: 14px;
    padding-bottom: 30px;
    text-align: center;
}
.btn-news.btn-contests a {
    color: #808000;
    font-family: "poppins";
    font-size: 18px;
    transition: all 0.2s ease-in-out 0s;
}
.btn-news.btn-contests a:hover {
    color: orangered;
}
.btn-newsModal{
    font-family: Comfortaa;
    color: #808000;
    text-align: center;
}