#privacyPage{
    background-color: #f7faff;
    padding-top: 50px;
  }

  
.privacyHeadline {
    font-family: "comfortaa";
    font-size: 35px;
    color: #808000;
    background-color: #f7faff;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding-bottom: 30px;
    margin-top: 15px;
    margin-bottom: 150px;
    
  }


.privacySubheader{
  background-color: #f7faff;
  font-size: 22px;
  font-weight: bold;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  width: 100%;
  
  margin-top: -120px;
}

.privacy_summary {
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  padding: 70px;
  width: 100%;
  text-align: center;
  margin-bottom: 85px;
  margin-top: -60px;


}