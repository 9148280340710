* ::before ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#LifeAfterLifeServicePage {
	width: 100%;
	padding-top: 0px;
	background-color: #f7faff;
}

.sMain-title {
	font-size: 35px;
	color: #808000;
	font-family: "comfortaa";
	padding: 50px;
	font-weight: bold;

}

.text {
	color: #808000;
	font-size: 18px;
	font-family: "poppins";
}

.introBundle {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 225px;
}

.introMain {
	text-align: center;
}

.introParagraph {
	margin-top: 1.5rem;
	margin: 1.5rem 5rem;
}

.naturePic {
	width: 80%;
	border-radius: 10px;
}

.servicePic {
	display: block;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 576px) {
	.introButtonsCluster {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.learn:hover {
		cursor: pointer;
		background-color: #808000;
		color: #f7faff;
	}

	.deedBundle {
		width: 100%;
		margin-bottom: 100px;
		align-items: center;
		justify-content: space-between;
	}

	.deedInfo {
		float: right;
		/* width: 35%; */
		margin: 50px 0 50px 0;
		padding: 50px 0;
	}

	.deedMain {
		text-align: right;
	}

	.deedParagraph {
		text-align: right;
	}

	.wrong {
		float: right;
		margin-top: 10px;
		background-color: transparent;
		color: #808000;
		border: 2px solid #808000;
		margin-left: 0px;
		margin-right: 0px;
	}

	.wrong:hover {
		cursor: pointer;
		background-color: #808000;
		color: #f7faff;
	}

	.serviceBundle {
		width: 100%;
		padding-bottom: 100px;
	}

	.sMain {
		text-align: center;
	}

	.symbol {
		display: inline-block;
		text-align: center;
		color: #808000;
	}

	.item {
		padding: 5px;
	}

	.subtitle {
		text-align: left;
		font-family: "poppins";
		color: #808000;
		display: inline-block;
	}

	.serviceText {
		font-family: "poppins";
		color: #808000;
		text-align: left;
		font-size: 15px;
		width: 80%;
	}

	.item {
		width: 100%;
	}

	.pcIcon {
		margin-right: 10px;
		margin: 10px;
	}

	.trIcon {
		margin-left: 10px;
		margin: 14px;

	}
	.scpIcon {
		margin-right: 10px;
		margin: 14px;
	}

	.gslIcon {
		margin-right: 10px;
		margin: 21px;
	}

	.cmIcon {
		margin-right: 10px;
	}

	.serve-container {
		text-align: left;
		display: inline-flex;
		margin-bottom: 30px;
	}

	.gps {
		margin-bottom: 60px;
	}

}

