/* html, body { height: 100%; } */
canvas{ width:100%; }

/* .overlay {

    background-color: rgba(0, 0, 0, 0);
} */

.portfolio-wrapper{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-wrapper-mobile-overlap{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ellipse-portfolio-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.ellipse-portfolio{
    width: 125px;
    height: 125px;      
    background-color:#808000;
    border-radius: 50%;
    transform: translate(0, -30%);
}

.ellipse-portfolio-img{
    margin: 0px !important;
    transform: scale(1.5);
}

.name-portfolio{
    width: 125px;
    height: auto;
    color: #808000;
    text-align: center;
    font-family: Comfortaa;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transform: translate(0, -100%);
}

.activity-info-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.activity-info-heading{
    color: #808000;
    font-family: Comfortaa;        
    font-size: 25px;
    margin-left: 235px;
    margin-bottom: 5px;
}

.activity-bundle{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.activity-info-title{
    display: flex;
    width: 31vh;
    height: auto;
    color: #808000;
    font-family: Comfortaa;
    font-size: 20px;
    transform: translate(-5%);
    justify-content: right;
    align-items: center;
}

.activity-info-content{
    justify-content: right;
    padding-right: 10px;
    color: #808000;
    font-family: Comfortaa;
    text-align: center;
    font-size: 25px;
    background-color: #F8FAFE;
    border: 0.5px solid #808000;
    width: 320px;
    height: 50px;
    font-size: 20px;
    align-items: center;
    display: flex;
}

.activity-chart{
    position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
    width: 60%;
    margin-left: 20%;
    min-height:450px;
    /* height: */
}

.activity-wrapper{
    margin-top: 59px;
    width: 100%;
}

@media screen and (max-width: 900px){

    .activity-chart{
        position: relative;
        margin-top: 50px;
        margin-bottom: 30px;
        width: 90%;
        margin-left: 5%;
    }

    .activity-info-content{
        width: 250px;
    }

    .activity-info-title{
        width: 15vh;
        height: auto;
        font-size: 15px;
        text-align: right;
    }

    .activity-info-heading{   
        font-size: 25px;
        margin-left: 10px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 600px){

    .activity-bundle {
        flex-direction: column;
    }

    .activity-info-content{
        width: 250px;
        justify-content: center;
        padding-right: 0px;
        margin-bottom: 10px;
    }

    .activity-info-title {
        width: 100%;
        height: auto;
        font-size: 15px;
        justify-content: center;
        margin-left: 11px;
    }

    .portfolio-wrapper-mobile-overlap{
        margin-left: -51.5%;
        width: 100%;
        /* margin: auto; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chart-container{
        min-height: 450px;
    }
}