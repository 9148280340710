#termsPage{
    background-color: #f7faff;
    padding-top: 150px;
}
.terms{
    padding-left: 15px;
}
.termsHead{
    text-align: left;
    padding-top: 50px;
}