* {
    box-sizing: border-box;
}
#career_body{
    background-color: #f7faff;
}
.careersPage {
    color: #808000;
    padding-top: 50px;
    margin-bottom: 0px;
    /* font-family: "poppins"; */
    font-family: 'comfortaa';
    background-color: #f7faff;
    margin: 0px;
    width: 100%;
}

h1 {
    padding: 10px;
}
.footerCareer{
    color: #808000;
    padding-top: 0;
    padding-bottom: 50px;
    margin-top: 0px;
    margin-bottom: 100px;
   padding-left: 10px;
    font-family: 'comfortaa';
    background-color: #f7faff;
    margin: 0px;
}

.row{
    color:#808000;

}
.careers-header{
    padding: 0;
    font-size: 2rem;
    margin-left: 50px;
    text-align: center;
    margin-bottom: 30px;
}

.mission-container {
    text-align: center;
    display: inline-flex;
    margin-left: 50px;
    font-size: large;

}

.card-container{
    display: flex;
    justify-content: center;
    padding: 50px;
    background-color: #f7faff;
    display: flex;
}

.col-lg-6 {
    flex: 1;
    display: flex;
    background-color: #f7faff;
  }

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
  }

.career-card.card {
    background-color: #f7faff;
    border: none;
  }

.volunteerBtn:hover{
    background-color: #808000;
    border:0px;
    height: 45px;
    width: 160px;
    border-radius: 25px;
    color: #f7faff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.career-card-title.card-title{
    color: #808000;
    font-size: 1.25rem;
}

.card-text{
    color: #808000;
}

.volunteerBtn{
    background-color:#f7faff;
    border: 2px #808000;
    border-style: solid;
    border-radius: 25px;
    height: 45px;
    width: 160px;
    color: #808000;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

}

.internBtn:hover{
    background-color: #808000;
    border:0px;
    height: 45px;
    width: 160px;
    /* width: 45px; */
    border-radius: 25px;
    color: #f7faff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.internBtn {
    background-color:#f7faff;
    border: 2px #808000;
    border-style: solid;
    border-radius: 25px;
    height: 45px;
    width: 160px;
    color: #808000;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;

}