.App {
  background-color: #F7FAFF;
  display: flex;
  flex-direction: column;
}

.content-padding{
  width: 100%;
  padding-top: 142px;
}

.app-sidemenu{
    height: 100%;
    display: flex; 
    position: relative;
    background-color: #D9D9D9;
}