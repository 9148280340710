.healPage{
  background-color: #f7faff;
  padding-top: 50px;
  padding-bottom: 150px;
}
.promo-vid-wrapper{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
  margin-left: auto;
  margin-right: auto;
}
.promo-vid{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.textBundleHeal1{
  width: 60%;
  float: right;
}
.textBundleHeal2{
  width: 60%;
  float: left;
  margin-top: -5%;
}
.textBundleHeal3{
  width: 60%;
  float: right;
  margin-top: -5%;
}

.textBundleHeal4{
  width: 100%;
  float: right;
  margin-top: -5%;
  margin-bottom: -10px;
}

.protectPlanetPic{
	width: 28%;
	float: left;
  margin-bottom: -13%;
  margin-left: 5%;
}
.coupleDog{

  width: 28%;
	float: right;
  margin-top: -5%;
  margin-bottom: -10%;
}
.cabinPic {
  width: 30%;
  float: right;
  
  /* margin-top: -5%; */
 
  background-color: #f7faff;
  

}
.objectiveMain{

  background-color: #f7faff;
}
.objectiveText{
  background-color: #f7faff;
}
.importantMain{

  background-color: #f7faff;
}
.objective_summary_heal {
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  padding-bottom: 70px;
  width: 100%;
  margin-bottom: 85px;
  margin-top: -60px;


}

.healFormBundle {
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #f7faff;

}

.__right{
  text-align: right;
  padding-right: 0 ! important; 
  margin-top: 30px;
}

.__left{
  text-align: left;
  padding-left: 0 ! important; 
  margin-top: 30px;
}

.__center{
  text-align: center;
}

.page-btn-2{
  transition-duration: 0.3s;
  border: 2px solid #808000;
  font-family: 'comfortaa';
  font-size: 24px;
  border-radius: 37.5px;
  height: 75px;
  width: 300px;
  
  text-align:center;
  text-decoration: none;
  color: #808000;

  display: block;
  padding-top: 18px;

  margin-left: auto;
  margin-right: auto;
}

.page-btn-2:hover{
  cursor: pointer;
  background-color: #808000;
  color: #F7FAFF;
}


.healHeadline {
  font-family: "comfortaa";
  font-size: 35px;
  color: #808000;
  background-color: #f7faff;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
  margin-top: 15px;
  
}


.subsection-title4 {
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
  
}

.slider-render-img-heal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 900px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;
}

.heal-slider{
  margin-top:-50px;
  margin-bottom: 100px;
}

.fullscreen-heal{
  margin-top: 50px;
}

.slider-fullscreen-heal{
  width: 100%;
}

@media screen and (max-width: 1400px){
  #heal_body{
    margin-bottom: 140%;
  }

  .__right{
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .__left{
    margin-top: 50px;
    margin-bottom: 30px;
  }

}
@media screen and (max-width: 1200px){
  #heal_body{
    margin-bottom: 180%;
  }
}

.textBundleHeal1{
  width: 65%;
}
.protectPlanetPic{
  width: 25%;
}
.textBundleHeal2{
  width: 65%;
}
.cabinPic{
  width: 30%;
}

@media screen and (max-width: 1024px){
  .protectPlanetPic{
    margin-top: 10%;
  }
  .cabinPic{
    margin-top: 10%;
  }
  .coupleDog{
    margin-top: 8%;
  }

  .__right{
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .__left{
    margin-top: 50px;
    margin-bottom: 30px;
  }

}
@media screen and (max-width: 992px){
  #heal_body{
    margin-bottom: 10%;
  }
  .textBundleHeal1{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -5%;

  }
  .textBundleHeal2{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -10%;

  }

  .textBundleHeal4{
    margin-top: 20%;
  }
  .protectPlanetPic{
    margin-bottom: 10%;
  }
  .subsection-title4{
    text-align: center;
    margin-left: 0%;
  
  }
  .objective_summary{
    text-align: center;
    margin-bottom: 0%;
  }
.protectPlanetPic{
  width: 40%;
  float: none;
  margin-left: 30%;
  margin-top: -5%;

}

.textBundleHeal3{
  width: 100%;
  float: none;   
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -10%;

}
.coupleDog{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;
}
.cabinPic{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;
}


@media screen and (max-width: 800px){

  .__right{
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .__left{
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .slider-render-img-heal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
  }

  .heal-slider{
    margin-top:-100px;
  }
  
  .fullscreen-heal{
    margin-top: 100px;
  }
}

@media screen and (max-width: 500px){
  .heal-slider{
    margin-top:-250px;
    margin-bottom: -100px;
  }
  .__right{
    text-align: center;
    padding-right: 0 ! important; 
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .__left{
    text-align: center;
    padding-right: 0 ! important; 
    margin-top: 50px;
    margin-bottom: 15px;
  }
}
}