@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Poppins);

#LifeAfterLifeInvestPage {
    width: auto;
    background-color: #f7faff;
  }

.formBundle{
  margin-top: -75px;
}
.text {
    color: #808000;
    font-size: 18px;
    font-family: "poppins";
  }


.investBundle {
    margin-top: 100px;
    align-items: center;
    justify-content: space-between;
  }

.investInfo {
    float: left;
    width: 100%;
    text-align: middle;
  }

.investMainTitle {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 35px;
    color: #808000;
    font-family: "comfortaa";
    font-weight: bold;
  
  }

.investButtonInner {
    text-decoration: none;
    background-color: transparent;
    color: #808000;
    border: 2px solid #808000;
    display: block;
    transition-duration: 0.3s;
    font-family: "comfortaa";
    font-size: 16px;
    border-radius: 25px;
    height: 50px;
    width: 160px;
    margin-right: 0px;
    margin-top: 30px;
    text-align: center;
    padding-top: 12px;
  }


.investButtonInner:hover {
    cursor: pointer;
    background-color: #808000;
    color: #f7faff;
  }

.grandparentsPicBundle {
    width: 100%;
    margin-top: 50px;
  }
.footnote {
  text-align: center;
  color: #808000;
  font-family: "poppins";
}
#ref1{
  color: #401C1C; 
  text-decoration: none;
}
#ref_jump{
  color: #401C1C; 
  text-decoration: none;
}

@media only screen and (max-width: 1209px) {
  .investButtonInner {
    font-size: 12px;
    height: 35px;
    width: 112px;
    padding-top: 9px;
    }
  }

@media only screen and (max-width: 996px) {
    .investButtonInner {
      font-size: 9px;
      height: 27px;
      width: 90px;
      padding-top: 6px;
      }
  }




