
.button-link-wrapper {
    display: flex;
    align-items: center; 
    border: 2px solid #808000;
  }

  
.banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    font-size: 18px;
    font-family: arial;
    /* color: #401c1c; */
    color: #808000;
    font-weight: bold;
    background-color: #F7FAFF;
    height: 40px; 
    text-align: center;
    z-index: 1000;
    order: 1
}

.banner:hover {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    font-size: 18px;
    font-family: arial;
    color: #401c1c;
    font-weight: bold;
    background-color: #F7FAFF;
    height: 40px; 
    text-align: center;
    z-index: 1000;
    order: 1
}



.disableBtn-banner{
    display: block;
    background-color: #F7FAFF;
    border: 2px solid #808000;
    color: #808000;
    border-radius: 50px;
    cursor: pointer;
    font-family: arial;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    margin-right: 2.5px;
    width: 20px;
    height: 20px;
    text-align: center;
    z-index: 1001;
    order: 2
}