* ::before ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.informedPage{
    padding-top: 120px;
    width: 100%;
    background-color: #F7FAFF;
}
.inform-title {
    width: 100%;
    font-size: 35px;
    background-color: #f7faff;
    color: #808000;
    font-family: "comfortaa";
    padding-top: 60px;
   
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    

}

.inform-container {
    width: 100%;
    /* padding-top: 90px; */
    background-color: #f7faff;
    margin-bottom: 180px;
}


.informedFormBundle {
    padding-top: 10px;
}

.informedContactForm {
    background-color: #F7FAFF;
}