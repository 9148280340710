#LifeAfterLifePhiloPage{
  background-color: #f7faff;
  margin-top: 50px;
}
.philoSubheader{
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  padding: 70px;
  width: 100%;
  
  margin-top: -60px;
}
.subsection-title3{
  text-align: center;
  align-items: center;

  
}
.subsection-title3 {
  margin-bottom: 10px;
  margin-top: 40px;
  text-align: center;
  align-items: center;
  
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
  
}
.textBundlePhilo1{
  width: 60%;
  float: right;
  text-align: left;
  margin-bottom: -10%;
}
.textBundlePhilo2{
  width: 60%;
  text-align: left;
  margin-top: 30%;
  margin-bottom: -10%;
  

}
.terrarium-one {
  width: 20%;
  float: left;

  /* margin: auto; */
  
  margin-left: 15%;
  margin-bottom: 5%;
  background-color: #f7faff;

}

.terrarium-two{
  width: 20%;
  margin-top: -22%;
  float: right;
  margin-right: 10%;
  
  /* margin: auto; */
 
 
  background-color: #f7faff;

}
.textBundlePhilo3{
  width: 90%;
  text-align: center;
}
.LastTerrarium{
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -10%;
}
.objectiveMain{

  background-color: #f7faff;
}
.objectiveText{
  background-color: #f7faff;
}
.importantMain{

  background-color: #f7faff;
}
.objective_summary {
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  padding: 70px;
  width: 100%;
  margin-bottom: 85px;
  margin-top: -60px;


}




.philoFormBundle {
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #f7faff;

}





.philoHeadline {
  font-family: "comfortaa";
  font-size: 35px;
  color: #808000;
  background-color: #f7faff;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
  margin-top: 15px;
  margin-bottom: 5%;
  
}


.subsection-title3 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;

  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
  
}
@media screen and (max-width: 1400px){


.terrarium-one{
  width: 15%;
 
  margin-bottom: 10%;
}
}
@media screen and (max-width: 1200px){
.terrarium-one{
  margin-top: 5%;
}
  .terrarium-two{
    margin-top: -30%;
  }
  .textBundlePhilo2{
    margin-top: 40%;
  }
}
@media screen and (max-width: 1024px){
  .textBundlePhilo1{
    width: 60%;
  }

  .textBundlePhilo2{
    margin-top: 70%;
  }
  .terrarium-one{
    margin-top: 15%;
  }
   .terrarium-two{
    margin-top: -50%;
  }

}
@media screen and (max-width: 768px){
  .textBundlePhilo1{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
   

  }
  .sub
   .textBundlePhilo1{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
   

  }
  .section-title3{
    text-align: center;
 margin-top: 0%;
  }
  .objective_summary{
    text-align: center;
    margin-bottom: 0%;
  }
.terrarium-one{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: 0%;
margin-bottom: 5%;

}

.textBundlePhilo2{
  width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
.terrarium-two{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;
margin-bottom: 10%;
}
}

.textBundlePhilo3{
  margin-left: 5%;
}

/* test */