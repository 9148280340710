.urlBackground{
  background-color: #F7FAFF;
  height: 100%;
}
.urlBundle{
  padding-top: 100px;
  width:80%;
  padding-bottom: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.urlTitle{
  font-size: 40px;
  font-family: "comfortaa";
  color: #808000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.urlText{
  font-size: 16px;
  font-family: "poppins";
  color: #808000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}