
#storePage{
    background-color: #f7faff;
    max-width: 2000px !important;
}
.storePage{
    max-width: 2000px !important;
}
.store_formBundle{
    margin-bottom: 50px;
    background-color: #f7faff;
}

.storeJot {
    height: calc( 100vh - 100px ) !important; 
}