
* ::before ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#LifeAfterLifeWrongPage {
  background-color: #F7FAFF;
  padding-top: 50px;
}

.iframeLAL {
  width: 100%;
  height: 10000%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.wrongPage {
  background-color: #f7faff;
  padding-top: 200px;
}

.wrongTitle {
  font-family: "comfortaa";
  font-size: 35px;
  color: #808000;
  text-align: center;
  font-weight: bold;
  margin-top: -10px;
}

.container-wrong {
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-value {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-cremation{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.wrongText {
  width: 50%;
}

.wrongParagraph {
  font-family: "poppins";
  font-size: 20px;
  color: #808000;
  justify-content: center;
}

.valueText {
  width: 50%;
  text-align: center;
}

.subsection-title-wrong {
  margin-bottom: 10px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
}

.cremationText {
  width: 50%;
}

.wrong-pic {
  width: 40%;
  background-color: #f7faff;
}

.wrongBackgroundColor {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 50px;
}


.okBackgroundColor {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #808000;
  margin: 0px -110px;
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width:1000px) {

  .iframeLAL {
    /* width: 100%; */
    /* height: 100px; */
    margin-bottom: -100px;
  }

  .text-right {
    text-align: center;
  }
  
  .text-left {
    text-align: center;
  }

  .wrongPage {
    background-color: #F7FAFF;
    padding-top: 200px;
    height: 3140px;
  }

  .container-wrong {
    flex-direction: column-reverse;
  }
  
  .container-value {
    flex-direction: column;
  }
  .container-cremation{
    flex-direction: column-reverse;
  }
  
  .wrongText {
    width: 100%;
  }
  
  .valueText {
    width: 100%;
  }
  
  .cremationText {
    margin-top: -40px;
    width: 100%;
    margin-bottom: -50px;
  }
  
  .wrong-pic {
    width: 80%;
  }
  
}