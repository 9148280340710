* ::before ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.checkoutPage{
  background-color: #F7FAFF;
  width: 100%;
}
.join-render--text-wrapper{
  width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.join-render--top-border{
	width: 50%;
	height: 50px;
	margin: auto;
	margin-bottom: 10px;
	border: #F7FAFF solid 5px;
	border-bottom: none;
	border-top-right-radius: 10px;
	border-top-left-radius: 15px;
    box-shadow: 5px -5px 5px #ffffff30, -5px -5px 5px #ffffff30, 5px 5px 5px #ffffff30 inset , -5px 5px 5px #ffffff30 inset;
}
.join-render--bottom-border{
	width: 50%;
	margin: auto;
	margin-top: 10px;
	height: 50px;
	border: #F7FAFF solid 5px;
	border-top: none;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 15px;
    box-shadow: 5px 5px 5px #ffffff30, -5px 5px 5px #ffffff30, 5px -5px 5px #ffffff30 inset , -5px -5px 5px #ffffff30 inset;
}
.join-render--img{
  margin: 0 auto;
  position: absolute; 
  left: 50%; 
  transform: translate(-50%, 0); 
	width: 100%;
  min-width: 1200px;
}
.join-render--text{
  width: 80%;
	margin: 0 auto;
	font-size: 40px;
	font-family: "Comfortaa";
	font-weight: bold;
	color: #F7FAFF;
	text-align: center;
}

#checkoutTitle{
  margin-bottom: 30px;
  position: relative;
  width: 100%;
	height: 0;
	padding-bottom: max(33.3%, 400px);
  overflow-x: hidden;
}

#checkoutAfter{

    float: left;
    background-color: #F7FAFF
}

.checkoutTile{
   
    color:#808000;
    align-items: center;
    margin: 10px;
    
}
.Collapsible__trigger {
    display: flex;
    justify-content: space-between;

}
.collapsible--text{
    flex: 20;
}

.text__under-line{
    text-decoration: underline;
}

.text__under-line:hover{
    color: #401c1c;
}

.--plus-sign{
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    flex: 1;
    transition: .4s transform ease-out;
}

.--plus-sign__opened{
    transform: rotateZ(180deg);
}

.benefit-collapsible p{
    margin-bottom: 0;
}

.benefit-collapsible {
    margin-top: 10%;
    background-color: #808000;
    font-family: "Comfortaa";
    color: #F7FAFF;
    text-align: center;
    margin: 10px;
    padding: 10px 15px;
    
    border-radius: 15px;
    border-color: #eff0f173;
    border-width: 4px;
    border-style: solid;
}

img {
    margin: 5px;
    max-width: 100%;
    max-height: 100%;
}

.contributions{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
 
}
.contribution_title{
    font-family: "Comfortaa";
    font-size: 18px;

}
.contribution{
  
    display: inline-block;
    vertical-align: top;
    margin: 1em;
    font-weight: bold;
  
    width: 20%;
    text-align: left;
    font-family: "poppins";
    font-size: 12px;
    
   
}
#contribute_header{
    color:#808000;

    font-family: "Comfortaa";
    font-size: 22px;
    margin-bottom: 35px;
}
.terms{
   text-align: left; 
}
.tiles{
    float: right;
    width: 55%;
    font-weight: lgihter;
}
.checkout_Icons{
    
    width: 40%;
    float: left;
    height: 100%
}
#checkoutHeader {
    background-color: #F7FAFF;
    text-align: center;
    color: #808000;
    font-size: 22px;
    font-family: "Comfortaa";
    /* height: 150px; */
    /* margin-bottom: -40px; */

}
.checkout_large{

    background-color: #F7FAFF;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.checkout_form1{
    margin-top:0%;
    height: 650px;
    width: 48%;
    float: left;
    background-color: #F7FAFF;
  
}
.checkout_form2{
    
    margin-top:0%;
    height: 650px;
    width: 48%;
    float: right;
    background-color: #F7FAFF;

}
.checkout{
    margin-top: 10px;
    background-color: #F7FAFF;
}

.checkout_large{
    margin: 10px;
}

.checkoutAfter{
    margin: 10px;
}

.checkoutPage{
    background-color: #F7FAFF;
}

@media screen and (max-width: 1200px){
    #checkout_body{
        margin-bottom: 27%;
         
    }
    #checkoutHeader{
        margin-bottom: 5%;
    }
   
}
@media screen and (max-width: 1024px){
    #checkout_body{
        margin-bottom: 50%;
         
    }
    #checkoutTitle{
      margin-bottom: 5%;
    }

 
}
@media screen and (max-width: 768px){
    #checkout_body{
      margin-bottom: 210%; 
    }
    .contribution{
      width: 40%;
    }
    #checkoutHeader{
      margin-bottom: 10%;
    }

}


@media screen and (max-width: 480px){
    #checkout_body{
        margin-bottom: 575%;
         
    }
    .contribution{
      
        width: 90%;
  
       
    }
    .checkout_form1{
    width: 100%;
    height: 750px;
    margin-top: 5%;
 
    }
    .checkout_form2{
    width: 100%;
    float: left;
    }
}
@media screen and (max-width: 400px){
    .contribution{
        width: 90%;
    }
    /* #checkoutTitle{
        margin-bottom: 25%;
    } */
    /* #checkoutHeader{
        margin-bottom: 115%;
    
    } */
    .checkout_form1{
    width: 100%;
 
    }
    .checkout_form2{
    width: 100%;

    float: left;
    }
}



    [data-custom-class='body'],
    [data-custom-class='body'] * {
        background: transparent !important;
    }

    [data-custom-class='title'],
    [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
    }

    [data-custom-class='subtitle'],
    [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
    }

    [data-custom-class='heading_1'],
    [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
    }

    [data-custom-class='heading_2'],
    [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
    }

    [data-custom-class='body_text'],
    [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
    }

    [data-custom-class='link'],
    [data-custom-class='link'] * {
        color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
    }
