@import url(https://fonts.googleapis.com/css?family=Comfortaa);

.fixed-top {
  /* position: fixed; */
  top: 0;
  right: 0;
  left: 0;
  padding-left: 5% !important;
  z-index: 1030;
}

.NavbarItems {
  display: inline-flex;
  width: 100%;
  background-color: #F7FAFF;
  /* fix the postion */
  position: fixed;
  top: 0px;
  padding: 10px 10px;
  box-shadow: 0 3px 10px -6px #808000;
  padding-left: 80px;
}

.NavbarItems #navbar-light-example{
  padding-right: 80px;
}



.logoLink {
  width: min-content;
  margin-left: 30px;
}

.completelogo {
  float: left;
  display: inline-flex;
  width: 50%;
}

.logoImg {
  height: 105px;
}


.logoText {
  width: 28%;
  height: 105px;
  text-align: center;
  font-family: "Comfortaa";
  color: #808000;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 27px;
  line-height: 35px;
  padding-top: 6px;
}

.PageItems {
  float: right;
  margin-left: auto;
  display: inline-flex;
  margin-top: 42px;
  margin-right: 20px;
}

.pageTitles {
  text-decoration: none;
  font-family: "Comfortaa";
  color: #808000;
  /* margin-right: 10px; */
  /* margin-left: 10px; */
  font-size: 1.4rem;
  transition-duration: .5s;
  height: min-content;

}

a.pageTitles:hover {
  color: #808000;
  text-decoration: underline;

}




.who-dropdown {
  color: #808000;
  font-family: "Comfortaa";
  background-color: #808000;
  border-radius: 25px;
  font-size: 20px;
  padding-bottom: 30px;
}

#dropdown-toggle {
  color: #808000;
}

.a.dropdown-toggle.nav-link::after {
  color: #808000;
  background-color: #f7faff;
}

.nav-item:hover .dropdown-menu {
  display: block !important;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

#dropdown-item {
  color: #808000;
  background-color: #f7faff;
  /* margin: 3px; */
}

.dropdown-item:hover {
  color: #808000;
  text-decoration: underline;
  background-color: #f7faff;
  
}

.nav-title:hover {
  color: #808000;
  text-decoration: underline;
}

.nav-title {
  flex-direction: column;
}

/* 
.wheelbarrow {
  height: 100px;
  margin-bottom: 10px;
} */

.reservations {
  /* TO-DO: add padding to button*/
  color: #808000;
  background-color:#F7FAFF;
  border-style: solid;
  border-width: 2px;
  border-color: #808000;
  border-radius: 25px;
  margin-left: 10px;
}

.navbar-nav .nav-link {
  color: #808000 !important;
  padding-right: 0.5rem !important;
  padding-left: 1rem !important;
}

.navbar-nav .dropdown-menu {
  background-color: #F7FAFF;
}

.reservations:hover {
  border: 2px solid #808000;
  text-decoration: underline;
  color: #F7FAFF;
  border-radius: 25px;
}

.r-icon {
  height: 50px;
  overflow-x:hidden;
  border-radius: 25px;
}

.accountImg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}


@media (max-width: 1000px) {
  .navbar-collapse {
    flex-direction: column;
    flex-wrap: wrap;
  }

  #dropdown-item {
    flex-direction: column;
    flex-wrap: wrap;
  }
  
  
}


 @media (max-width: 1000px) {

  .navbar-collapse {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

  .NavbarItems {
    display: inline-flex;
    width: 100%;
    max-width: 100vw;
    background-color: #F7FAFF;
    /* fix the postion */
    position: fixed;
    top: 0px;
    padding: 10px 10px;
    box-shadow: 0 3px 10px -6px #808000;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1);
  }
  .navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  #dropdown-item {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
} 

} 

@media (max-width: 1220px) {
  .NavbarItems {
    padding-left: 0px;
  }
  .NavbarItems #navbar-light-example{
    padding-right: 0px;
  }
}