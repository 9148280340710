.land-background{
    padding-top: 50px;
    background-color: #f7faff;
    color: #808000;
    text-align: left;
    font-family: "Comfortaa";
}

.landMain p{
    font-family: "poppins";
    font-size: 20px;
    text-align: left;
}

.landMain h4{
    font-style: italic;
}

.landMain .textLeft{
    width: 50%;
}

.landMain img{
    width: 50%;
}

.landMain{
    display: flex;
    margin-bottom: 70px;
}

.landMain li{
    margin: 20px;;
}

.landMain span{
    font-size: 18px;
    font-weight: bolder;
}

.links{
    color: #808000;
}

.links:hover{
    color: #401c1c;
}

.landButton{
    display: inline-block;
    border: 2px solid #808000;
    color: #808000;
    font-family: 'comfortaa';
    font-size: 16px;
    width: 200px;
    border-radius: 25px;
    margin: 25px;
    text-align: center;
    text-decoration: none;
}
.landButton:hover{
    color: white;
    background-color: #808000;
}

.parkPic{
    margin-bottom: 60px;
}

@media screen and (max-width: 1400px){
    .landMain{
        flex-wrap: wrap;
    }

    .landMain .textLeft{
        width: 100%;
    }
    
    .landMain img{
        width: 100%;
    }
}
