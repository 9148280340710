* ::after, ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

#celebration_body{
    background-color: #f7faff;
}
.celebrateContainer {
    width: 100%;
	background-color: #f7faff;

}

.celebrateBackground{
    padding-top: 150px;
    width: 100%;
    background-color: #808000;
    height: 600px;
    margin-bottom: 20px;
   
}


.celebrateSubtext {
    font-family: "poppins";
    color: #808000;
    text-align: left;
    font-size: 15px;
    width: 80%;
    padding-left: 75px;
}


.celebrateTitle {
    font-size: 35px;
	background-color: #808000;
	color: #f7faff;
	font-family: "comfortaa";
	padding: 50px;
	margin-bottom: 20px;
	margin-left: -50px;
    margin-right: -60px
}

.celebSubtitle {
    text-align: left;
    font-size: 25px;
    font-family: "poppins";
    color: #808000;
    display: inline-block;
}

.mountains{
    border-radius: 15px;
    margin-bottom: 20px;
}