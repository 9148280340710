* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.footerContainer{
    max-width: 100%;
    background-color:#808000;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 25px;

}
ul {
    list-style: none;
}


.footerPage {
    background-color: #808000;
    padding: 30px 0;

}


.footerCol{
    width: 100%;
    padding: 10px 75px; 
}


.footerCol h4{
    font-family: 'comfortaa';
    font-size: 24px;
    color: #F7FAFF; 
    margin-bottom: 35px;
    font-weight: 500;
    transition: all .3s ease;
    position: relative;
}

.footerCol h4::before{
    content: ''; 
    position: absolute;
    left: 0; 
    bottom: -10px; 
    background-color: #F7FAFF;
    height: 2px; 
    box-sizing: border-box;
 
    width: 55px; 
}



.footerCol ul li:not(last-child){
    margin-bottom: 10px; 

}

.footerCol ul li a{
    font-size: 16px;
    color: #F7FAFF;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all .3s ease;
    font-family: 'poppins';
}


.footerCol ul li a.interact:hover{
    color: #401C1C;
    padding-left: 3px;
}

.fullscreenFooterCol{
    width: 100%;
    padding: 85px 65px; 
}


.fullscreenFooterCol ul li a{
    font-size: 16px;
    color: #401C1C;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all .3s ease;
    font-family: 'poppins';
}

.fullscreenFooterCol ul li a.interact:hover{
    color: #F7FAFF;
    padding-left: 3px;

}



.hr { 
    border: 1px solid #F7FAFF;
    opacity: 1;

   
} 

.contactFooterBundle {
    margin-left: 100px;

}

.contactEmail{
    /* padding-top: 25px; */
    /* margin-left: 40px; */
    
    background-color: transparent;
  

    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 18px;
 
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 300;
    text-decoration: none;
}



.header {
    font-size: 35px;
    color: #F7FAFF;
    font-family: 'comfortaa';
    margin-bottom: 20px;
    justify-content: space-between;
    /* align-items: flex-start; */
    display:flex;
}

.info {
    font-size: 18px;
    color: #F7FAFF;
    font-family: 'poppins';
    margin-top: 15px;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;


}

.contactUs {
    /* transition-duration: 0.3s; */
    background-color: transparent;
    border: 2px solid #808000;
    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 16px;
    border-radius: 25px;
    height: 46px;
    width: 160px;
    margin-top: 0px;
    /* margin-left: -25px; */
    font-weight: 300;
    

}

.contactUs:hover {
     /* text-decoration: underline; */
    cursor: pointer;
 
}

.reservationBtn{
    background-color: transparent;
    border: 1px solid #808000;
    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 16px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    flex: inline-flex;
}

.reservationBtn:hover{
    cursor: pointer;
 


}

.reservation {
    margin-bottom: 25px;
    width: 90%;
    padding: 2%;

}

.reservation:hover {
    font-size: 17px;

}


.socialMediaBundle {
    /* margin-top: 25px; */
    margin-left: 100px;
    /* margin-top: -50px; */
    display: inline-flex;
    flex-wrap: wrap;
}
.infoPrivacy{
    margin-left: 100px;
    display: inline-flex;
    flex-wrap: wrap;
}

.trademark {
    margin-left: 20px;
    color: #F7FAFF;
    font-size: 12px;
    font-family: "comfortaa";
    margin-top: 10px;
}
.pclink{
    color: #F7FAFF;
    font-size: 15px;
    font-family: "comfortaa";
    text-decoration: none;
    white-space: nowrap;
}
.privacy {
    display: inline-flex;
    color: #F7FAFF;
    font-size: 15px;
    font-family: "comfortaa";
    margin-top: 10px;
    margin-right: 20px;
}

.footerIcon {
    margin-left: 10px;
}


.nav-page {
    transition-duration: 0.3s;
    background-color: transparent;
    border: 2px solid #808000;
    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 16px;
    border-radius: 25px;
    height: 50px;
    width: 160px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 50px;
}

.nav-page:hover {
    cursor: pointer;
    border: 1px solid #F7FAFF;
}

.benefactors-col {
    float: right;
    width: 33%;
    margin-top: -290px;
    margin-left: 490px;
    border-radius: 25px;
    background-color: transparent;
    color: #F7FAFF;
    font-family: 'comfortaa';
    font-size: 16px;
}



.bene-pages{
    transition-duration: 0.3s;
    background-color: transparent;
    border: 2px solid #808000;
    color: #F7FAFF;
    font-family: 'comfortaa';
    font-size: 16px;
    border-radius: 25px;
    height: 50px;
    width: 160px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 50px; 
}

.bene-pages:hover {
    cursor: pointer;
    border: 1px solid #F7FAFF;
    border-radius: 25px;
}


.row:after {
    content: "";
    display: table;
    clear: both;
}

.topic {
    color: #F7FAFF;
    margin-top: 14px;
    font-size: 18px;
    font-family: "comfortaa";
    text-decoration: underline;
    margin-left: 70px;
}

.short-topic{
    color: #F7FAFF;
    margin-top: 14px;
    font-size: 18px;
    font-family: "comfortaa";
    margin-left: 90px;
    text-decoration: underline;
}

.contactUsPatrons {
        /* transition-duration: 0.3s; */
        background-color: transparent;
        border: 2px solid #808000;
        color: #F7FAFF;
        font-family: 'poppins';
        font-size: 16px;
        border-radius: 25px;
        height: 46px;
        width: 160px;
        margin-top: 0px;
        /* margin-left: -25px; */
        font-weight: 300;
}

.contactUsPatrons:hover{
    cursor: pointer;
    border: 1px solid #F7FAFF;

}

.eventsColumn {
    color: #F7FAFF;
    font-family: "comfortaa";
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 150px;
    margin-left: 900px;
}

.eventsColumnPatrons{

    flex-direction: column;
}


.eventsColumnTitle {
    text-decoration: underline;
    font-size: 18px;
    margin-left: 40px;
    z-index: 2;
}

.patrons-col:hover {
    cursor: pointer;
    border: 1px solid #F7FAFF;
}



.flex-container {
    display: flex;

}

 .flex-child {
    flex: 1;
    /* border: 2px solid yellow; */
 }   

.flex-child:first-child {
    margin-right: 0px;
} 

.cart {
    height: 45px;
    border-radius: 25px;
    
}





.vl {
    border-left: 2px solid #F7FAFF;
    height: 400px;
    /* margin-right: 40px; */
}


/* hr:not([size]) {
    display: none;
} */



.macro-wrapper{
    background-color: #808000;
    display: none; 
}
.wrapper{
    display: none; 
    width: 100%; 
    justify-content: center;
    align-items: center;
    background: #808000; 
    padding-right: 35px;
    padding-left: 30px;
    padding-top: 25px;
}


.accordion {
    width: 100%; 
}
 
.item{
    background: #808000; 
    padding: 10px 20px; 
}

.info {
    font-size: 15px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
}

.title-wrapper {
    color: #fff;
    font-family: 'poppins';
    margin-bottom: 20px;
    justify-content: space-between;
    /* align-items: flex-start; */
    display:flex;
    align-items: center;
    cursor: pointer;
}

.title-wrapper:hover{
    color: #401C1C; 

}
.title {
    font-size: 30px;
    font-family: "comfortaa";
}

.title-wrapper .plus-minus{
    font-size: 38px;
}


.content {
    color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1); 
}

.content.show {
    height: auto; 
    max-height: 9999px;
    color: #fff;
    transition: all 0.5s cubic-bezier(1,0,1,0); 
}

.contactEmail{
    /* margin-left: 40px; */
   
    background-color: transparent;
   
    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 18px;
  
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 300;
    text-decoration: none;
}


.socialMediaBundle {
    /* margin-top: 25px; */
    margin-left: 100px;
    /* margin-top: -50px; */
    display: inline-flex;
}
.infoTrademark {
    display: flex;
    justify-content: space-between;
}

.logo {
    color: #F7FAFF;
    font-size: 30px;
    margin-left: 15px;
    margin-right: 15px;
    transition: .4s;
}

.logo:hover {
    color: #401C1C;
    font-size: 31px;
}

.trademark {
    margin-left: 100px;
    color: #F7FAFF;
    font-size: 15px;
    font-family: "comfortaa";
    margin-top: 10px;
}

.footerIcon {
    margin-left: 10px;
}

.hr { 
    border: 1px solid #F7FAFF;
    opacity: 1;
   
} 


.reservationBtn{
    background-color: transparent;
    border: 1px solid #808000;
    color: #F7FAFF;
    font-family: 'poppins';
    font-size: 15px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;

}

.pointer{
    cursor: pointer;
}




@media screen and (max-width:1500px) {

   

    .flex-container {
        display: flex;
        flex-direction: column;
    }

    .hr { 
        border: 2px solid #F7FAFF;
        margin-right: 40px;
         /* border-top: 1px ;  */
    } 

    .vl {
        display: none;
    }



    .learnMoreBtn {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 130px;
        margin-bottom: 40px;
    }

  


    .flex-child {
        flex-direction: column;

        /* border: 2px solid yellow; */
    }   
    
    .eventsColumn {
        color: #F7FAFF;
        font-family: "comfortaa";
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: 650px;
        margin-left: 200px;
    
    }

    .eventsColumnTitle {
        text-decoration: underline;
        font-size: 18px;
        text-align: center;
        color: #F7FAFF;
        margin-right: 100px;
    }
    .short-topic{
        color: #F7FAFF;
        font-size: 18px;
        font-family: "comfortaa";
        margin-left: 95px;
        text-decoration: underline;
    }

    .footerPage {
        display: none; 
    }
    .wrapper {
        display: flex; 
        width: 100%; 
        justify-content: center;
        align-items: center;
    }
    .macro-wrapper {
        display: block; 
        
    }

    

}
@media screen and (max-width:1500px) {

.socialMediaBundle{
    margin-left: 0%;
}
.trademark{
    margin-left: 3%;
    width: 63%;
}

}