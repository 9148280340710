#build_body{
  background-color: #f7faff;
  
}

.spacer {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5%;
  margin-bottom: 30px;
  width: 80% !important;
}

.build--promo-vid-wrapper{
  width: 80% ! important;
  padding-bottom: 45% ! important;
  margin-bottom: 30px;
  /* padding-bottom: 35.087% ! important; */
}

.slider-render-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 500px;
}

.build-slider{
  position: relative;
}

.slider-fullscreen{
  width: 100%;
}
.img-fullscreen{
  background-size: contain;
  height: 650px;
  width: 100%;
}

.fullscreen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding-top: 5%;
  z-index: 9999;
}

.fullscreen-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.btn-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
}

.fullscreen-text {
  z-index: 0 !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* semi-transparent gray */
  z-index: -1; /* behind the slider */
}

.buildSubheader{
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  width: 100%;
  
  margin-top: -60px;
}
.subsection-title-build1 {
  text-align: right;
  margin-right: 65px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
}

.subsection-title-build2 {
  text-align: left;
  margin-left: 65px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
}

.textBundleBuild1{
  width: 60%;
  float: right;
  text-align: right;
  margin-bottom: -10%;
  margin-top: 5%;
}
.textBundleBuild2{
  width: 60%;
  float: left;
  text-align: left;
  margin-bottom: -7%;

}

.textBundleHeal4{
  width: 100%;
  float: right;
  margin-bottom: 50px;
}

.traditionalCemeteryPic {
  width: 35%;
  float: right;
  top: 50%;
  margin-left: 50px;
  margin-top: 8%;
  margin-bottom: 5%;
  background-color: #f7faff;

}

.urbanparkPic {
  width: 60%;
  margin-left: 20%;
  background-color: #f7faff;
}

.volunteerPic {
  width: 35%;
  float: left;
  
  /* margin: auto; */
 
  margin-right: 50px;
  margin-bottom: 5%;
  margin-top: 5%;
  background-color: #f7faff;

}
.objectiveMain{

  background-color: #f7faff;
}
.objectiveText{
  background-color: #f7faff;
}
.importantMain{

  background-color: #f7faff;
}
.objective_summary {
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  padding: 70px;
  width: 100%;
  margin-bottom: 85px;
  margin-top: -60px;


}

.buildFormBundle {
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #f7faff;

}

.buildHeadline {
  /* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "comfortaa";
  font-size: 45px;
  color: #808000;
  width: 100%;
  text-align: center;
  font-weight: bold;
  z-index: 2;
}

@media screen and (max-width: 1400px){

  .textBundleBuild1{
    width: 55%;
  }

  .textBundleBuild2{
    width: 55%;
  }
  
  .traditionalCemeteryPic {
    width: 35%;
    float: right;
    top: 50%;
  
    /* margin: auto; */
    
    margin-left: 50px;
    margin-top: 18%;
    margin-bottom: 5%;
    background-color: #f7faff;
  
  }
  
  .volunteerPic {
    width: 35%;
    float: left;
    
    /* margin: auto; */
   
    margin-right: 50px;
    margin-bottom: 5%;
    margin-top: 14%;
    background-color: #f7faff;
  
  }
}

@media screen and (max-width: 1200px){

  .textBundleBuild1{
    width: 55%;
  }

  .textBundleBuild2{
    width: 55%;
  }
  
  .traditionalCemeteryPic {
    width: 37%;
    float: right;
    top: 50%;
  
    /* margin: auto; */
    
    margin-left: 50px;
    margin-top: 28%;
    margin-bottom: 5%;
    background-color: #f7faff;
  
  }
  
  .volunteerPic {
    width: 37%;
    float: left;
    
    /* margin: auto; */
   
    margin-right: 50px;
    margin-bottom: 5%;
    margin-top: 24%;
    background-color: #f7faff;
  
  }
}

@media screen and (max-width: 1000px){

  .subsection-title-build1 {
    text-align: center;
    margin-left: 65px;
  }
  
  .subsection-title-build2 {
    text-align: center;
    margin-right: 60px;
  }

  .textBundleBuild1{
    width: 100%;
    float: none; 
    text-align: center;  
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    order: 2;
  }

  .objective_summary{
    text-align: center;
    margin-bottom: 0%;
  }

  .textBundleBuild2{
    width: 100%;
    float: none;  
    text-align: center; 
    margin-left: auto;    
    margin-right: auto;
    margin-top: 0%;
    order: 2;
  }


  .textBundleBuild3{
    margin-bottom: 5%;
  }

  .volunteerPic{
    width: 60%;
    float: none;
    margin-left: 20%;
    margin-top: -8%;
    order: 1;
  }

  .traditionalCemeteryPic{
    width: 60%;
    float: none;
    margin-left: 20%;
    margin-top: 0;
    order: 1;
  }

  .container{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 750px){

  .volunteerPic{
    width: 60%;
    float: none;
    margin-left: 20%;
    margin-top: 0%;
    order: 1;
  }
}