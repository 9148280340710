.overlay{
  background-color: rgba(0, 0, 0, 0);
}

.pro-sidebar{
    background-color: #808000;
    z-index: 1;
}

.pro-menu ul{
    margin: 0 !important;
    padding: 0 !important;
}

.pro-item-content {
    margin-top: 5px; /* Add a margin to the top */
    color: #F7FAFF;
    font-family: Comfortaa;
    font-size: 23px;
    text-align: center; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
  }

  .sidebar-flex-item{
    display: flex;
    /* padding-left: 20px; */
    border-bottom: 1px solid #F7FAFF; 
    width: 300px;
    height: 62px;
  }

  .sidebar-flex-item-collapsed{
    display: flex;
    width: 70px;
    height: 62px;
    border-bottom: 1px solid #F7FAFF; 
  }

  .sidebar-header-flex{
    display: flex;
    border-bottom: 1px solid #F7FAFF; 
    height: 62px;
    align-items: center; 
    justify-content: right; 
  }

  .sidebar-header-collapsed {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #F7FAFF; 
    height: 62px;
  }

  .suffix-wrapper{
    margin-left: 60px;
    margin-right: 20px;
  }

  .sidebar-header-text{
    color: #F7FAFF;
    font-family: Comfortaa;
    font-size: 25px;
  }

  .pro-inner-item{
    display: flex !important;
  }

  .sidebar-footer{
    text-align: "center";
  }

  .exit-icon{
    height: 35px;
    margin-left: 15px;
  }

  .sidebar-footer-item-collapsed{
    display: flex;
    width: 70px;
    height: 62px;
    margin-top: 150px;
    border-bottom: 1px solid #F7FAFF; 
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 185px;
    border-bottom: 1px solid #F7FAFF; 
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      color: #F7FAFF;
      font-family: Comfortaa;
      text-decoration: none;
      background-color: #808000;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
    }
    .sidebar-btn:hover, .sidebar-btn:active{
        background-color: #808000 !important;
        color: #F7FAFF;
        background: rgba(255, 255, 255, 0.05) !important;
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  .profile-active-link{
    font-size: 24px;
  }

@media screen and (max-width: 750px){

  .sidebar-flex-item{
    width: 200px;
  }

  .profile-active-link{
    font-size: 21px;
  }

  .suffix-wrapper{
    margin-left: 15px;
    margin-right: 10px;
  }

  .sidebar-header-text {
    color: #F7FAFF;
    font-family: Comfortaa;
    font-size: 21px;
  } 

}

@media screen and (max-width: 600px){

  .sidebar-header-flex {
    justify-content: center;
    padding-left: 50px;
}

  .sidebar-flex-item{
    width: 100%;
    justify-content: center;
  }

  .profile-active-link{
    font-size: 24px;
  }

  .sidebar-header-text {
    color: #F7FAFF;
    font-family: Comfortaa;
    font-size: 25px;
  } 

}