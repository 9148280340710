.community-background{
  padding-top: 50px;
  width: 100%;
  background-color: #f7faff;
  height: 600px;
  display:inline-block;
  color: #808000;
  text-align: center;
}

.community_context{
  font-family: "poppins";
  font-size: 20px;
  color: #808000
}