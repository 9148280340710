

@media screen and (min-width:700px) {
  .newsletterBtn {
    font-family: "comfortaa";
    text-align: center;
    color: #F7FAFF;
    margin: auto;
    padding: 8px;
    border-style: solid;
    border-color: #eff0f173;
    box-shadow: 5px 5px 8px rgba(47, 79, 79, 0.259);
    border-radius: 25px;

    background: #808000;
    flex-wrap: nowrap;

  }
  .newsletterBtnWrapper {
    position: fixed;
    top: 90%;
    right: 20px;
  }
}


.modal {
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal_content {
  background-color: #F7FAFF;
  box-shadow: 10px 10px 30px #808000;
  display: inline-block;

  max-width: 600px;
  min-height: 100px;
  vertical-align: left;
  width: 60%;
  position: fixed;
  border-radius: 8px;
  padding: 15px 5%;
  top: 5%;
  left: 5%;
}

.close {
  background-color: #F7FAFF;
  border: 3px solid #808000;
  color: #808000;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  font-family: arial;
  font-weight: bold;
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 25px;
  line-height: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.close:hover {
  color: orangered;
  cursor: pointer;
}

.disableBtn {
  display: block;
  visibility: hidden;
  background-color: #F7FAFF;
  border: 2px solid #808000;
  color: #808000;
  border-radius: 50px;
  cursor: pointer;
  font-family: arial;
  font-weight: bold;
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 10px;
  line-height: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
}

.disableBtn:hover{
  color: orangered;
}

.newsletterBtnWrapper:hover .disableBtn{
  visibility: visible;
}

.newsletterBtnWrapper:active .disableBtn{
  visibility: visible;
}

@media screen and (max-width:700px){ 
  .newsletterBtn {
    font-family: "comfortaa";
    text-align: center;
    color: #F7FAFF;
    margin: auto;
    padding: 8px;
    border-style: solid;
    border-color: #eff0f173;
    box-shadow: 5px 5px 8px rgba(47, 79, 79, 0.259);
    border-radius: 25px;
    flex-wrap: nowrap;

    background: #808000;

  }

  .newsletterBtnWrapper {
    position: fixed;
    top: 90%;
    right: 20px;
    z-index: 3;
  }

  .disableBtn {
    visibility: visible;
    top: -5px;
    right: -5px;
  }
}