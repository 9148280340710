
#LifeAfterLifeWhoAreWePage {
    background-color: #F7FAFF;
}

.bios {
    width: 100%;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
    background-color: #F7FAFF;

}

.team-heading{
    color: #808000;
    text-align: center;
    font-family: "Comfortaa";
}

.bundle {
    border: 2px solid #808000;
    border-radius: 5px;
    margin: 20px;
    padding-top: 10px;

}

.logoBundle {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bioBundles {
    display: inline-flex;
    text-align: center;
}

.contactBundle {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.bioPics {
    /* height: 250px; */
    border-radius: 1000px;
    height: 175px;
    width: 175px;
    display: block;
    margin: 0px auto;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    margin-bottom: 15px;

}

.togglePic:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

}

.toggleBio:hover .bundle {
    background-color: #808000;
    border: 2px solid #F7FAFF;
    border-radius: 5px;
    /* margin: 30px; */
    padding-top: 10px;

}

.toggleBio:hover .bioDescription {
    color: #F7FAFF;
    font-family: 'poppins';
    background-color: #808000;
    text-align: center;
    width: 90%;
    font-size: 16px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

}

.togglecontactBundle:hover .toggleBio {
    border: 2px solid #F7FAFF;
    border-radius: 5px;
    margin: 30px;
    padding-top: 10px;
}

.toggleBio:hover .Name {
    color: #F7FAFF;
    background-color: #808000;
    font-family: 'comfortaa';
    text-align: center;
    width: auto;
    font-size: 20px;

}

.toggleBio:hover .linkedin {
    color: rgba(0, 119, 183, 1);
    /* background-color: #F7FAFF; */
    /* border-radius: 25px; */
    font-family: 'comfortaa';
    text-align: center;
    /* width: 10px; */
    /* height: 20px;  */
    /* width: auto; */
    font-size: 20px;
    /* border: solid #F7FAFF 1px; */

}


.Name {
    font-size: 20px;
    color: #808000;
    font-family: 'comfortaa';
    text-align: center;
    width: auto;

}

.linkedin {
    width: 100%;
    margin-left: 5px;
    font-size: 20px;
    color: #808000;
    margin-top: 11px;
    /* transition-duration: .2s; */
}

.linkedin:hover {
    cursor: pointer;
    color: #F7FAFF;
    background-color: #808000;

}

.titleBundle {
    display: inline-flex;
    margin-right: auto;
    margin-left: auto;
}

.bioDescription {
    text-align: center;
    width: 90%;
    font-size: 16px;
    color: #808000;
    font-family: 'poppins';
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.trail {
    font-size: 40px;
    color: #808000;

}

.winterPlants {
    height: 125px;
    width: 100%;
    margin: 0;
}

/* .winterPlantsTop{
    height: 125px;
} */