@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Poppins);

.slick-prev:before,
.slick-next:before {
        color: red;
        z-index: 999;
      }

.homePage {
  width: 100vw;
  background-color: #f7faff;
}

.lake-img {
  width: 800px;
  border-radius: 10px;
}

.LakePic {
  display: block;
  width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.hero-container {
  margin-top: -500px;
  background-color: #808000;
  object-fit: fill;
  margin-bottom: 200px;
}

.introText {
  z-index: 3;
}

.introText h1 {
  color: #fff;
  font-size: 110px;
  padding-top: 80px;
  text-align: center;
  margin-left: 20px;
  /* font-weight: bold; */
  font-family: "comfortaa";
  /* font-family: "poppins"; */
  z-index: 3;
  position: relative;
}

.introText p {
  margin-top: 8px;
  color: #fff;
  /* font-family: "comfortaa"; */
  font-family: "poppins";
  text-align: center;
  font-size: 30px;
  text-align: center;
  z-index: 3;
  position: relative;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.text {
  color: #808000;
  font-size: 18px;
  font-family: "poppins";
}

.introBundle {
  width: 100%;
  margin-right: auto;
  /*margin-left: auto; */
  margin-bottom: 225px;
}

.introButtons {
  display: inline-flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  gap: 5px;
  z-index: 3;
}

.introButtonsCluster {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
}

.homeBtn {
  transition-duration: 0.3s;
  background-color: transparent;
  border: 2px solid #808000;
  color: #808000;
  font-family: 'comfortaa';
  font-size: 16px;
  border-radius: 25px;
  height: 50px;
  width: 200px;
  border: 2px solid #808000;
}

.homeBtn:hover {
  cursor: pointer;
  background-color: #808000;
  color: #808000;
  border: 2px solid #808000;
}

.celebrateBundle {
  align-items: center;
  justify-content: space-between;
}

.celebrateMain-title-left {
  text-align: left;
  margin-bottom: 10px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
}

.familyPicBundle {
  width: 100%;
}

.celebrateMain-title {
  text-align: right;
  margin-bottom: 10px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
}

.celebrateParagraph-right{
  text-align: right;
  float: right;
}


.learnButtonInner {
  text-decoration: none;
  background-color: transparent;
  color: #808000;
  border: 2px solid #808000;
  display: block;
  transition-duration: 0.3s;
  font-family: "comfortaa";
  font-size: 16px;
  border-radius: 25px;
  height: 50px;
  width: 160px;
  margin-right: 0px;
  margin-top: 30px;
  text-align: center;
  padding-top: 12px;
}

.learnButtonInner:hover {
  cursor: pointer;
  background-color: #808000;
  color: #f7faff;
}

.philBtn{
text-decoration: none;
background-color: transparent;
color: #808000;
border: 2px solid #808000;
display: block;
transition-duration: 0.3s;
font-family: "comfortaa";
font-size: 16px;
border-radius: 25px;
height: 50px;
width: 160px;
margin-left: auto;
margin-right: auto;
margin-top: 30px;
text-align: center;
padding-top: 12px;
}

.philBtn:hover {
  cursor: pointer;
  color: #F7FAFF;
  background-color: #808000;
  text-decoration: none;
}

.learnMoreBtn{
  text-decoration: none;
  background-color: transparent;
  color: #808000;
  border: 2px solid #808000;
  display: block;
  transition-duration: 0.3s;
  font-family: "comfortaa";
  font-size: 16px;
  border-radius: 25px;
  height: 50px;
  width: 160px;
  margin-top: 30px;
  padding-top: 12px;
}

.video {
  object-fit: cover;
  height: 600px;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  
}

.subtitle {
  text-align: left;
  font-size: 25px;
  font-family: "poppins";
  color: #808000;
  display: inline-block;
}

.philBundle {
  padding-bottom: 50px;
  text-align: center;
}

.philInfo {
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
  /* margin-bottom: 50px; */
}

.serviceBundle {
  width: 100%;
  padding-bottom: 100px;
}

.lal-services {
  text-align: center;
  margin-bottom: 50px;
  /* margin-top: 20px; */
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  margin-top: 80px;

}

.philMain-title {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";

}

.grandparentPic {
  width: 48%;
}

.icon-wrapper {
  min-width: 200px;
  margin-left: -50px;
  text-align: center;
}
.symbol {
  display: inline-block;
  font-size: 70px;
  text-align: center;
  color: #808000;
}

.serviceText {
  font-family: "poppins";
  color: #808000;
  text-align: left;
  font-size: 15px;
  width: 90%;
  padding-top: 15px;
}

.pcIcon {

  margin: 10px;
  margin-top: 15px;
}
.trIcon {

  margin: 24px;
}
.scpIcon {

  margin: 14px;
  margin-top: 30px;
}

.gslIcon {

  margin: 21px;
}

.cmIcon {
  margin-left: 5px;
  margin-top: 20px;
}

.serve-container {
  /* text-align: center; */
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 10px;
  margin-left:-3.5%;
  /* margin-right:-2.5%; */
}

.gps {
  margin-bottom: 60px;
}

.title {
  margin-bottom: 0%;
}

.empty {
  display: none;
}

.img {
  width: 700px;
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.homelearnMoreBtn {
  text-decoration: none;
  background-color: transparent;
  color: #808000;
  border: 2px solid #808000;
  display: block;
  transition-duration: 0.3s;
  font-family: "comfortaa";
  font-size: 16px;
  border-radius: 25px;
  height: 50px;
  width: 160px;
  text-align: center;
  padding-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.homelearnMoreBtn:hover {
  cursor: pointer;
  border: 1px solid #F7FAFF;
  color: #F7FAFF;
  background-color: #808000;
  text-decoration: none;
}

.press-home-title{
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
}

.slick-slide{
  height: auto;
}

.slick-next:before {
  font-size: 25px !important;
}


.slick-track{
  max-height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.carousel-item-wrapper{
  width: 300px;
}

.slick-prev:before {
  font-size: 25px !important;
}

.home-carousel {
  height: auto; 
  width: auto; 
  transform: scale(0.9);
}  

.home-carousel-large{
  transform: scale(0.65);
}

.slick-slide {
  &:focus, a {
    outline: none;
  }
}

.as-seen-in-wrapper{
  background-color: #808000;
}

.slick-list{
  height: auto !important;
}
.as-seen-in-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: -20px;
  padding-top:55px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";

}
@media screen and (max-width: 1000px) {
  .serviceText{
    width: 80%;
  }


  .hero-container { 
    margin-bottom: 200px;
  }
}


@media screen and (max-width: 800px) {
  .video {
    height: 700px;
    margin-bottom: 100px;
  }

  .hero-container { 
    margin-top: -700px;
  }

  .slick-prev:before {
    font-size: 20px !important;
  }

  .slick-next:before {
    font-size: 20px !important;
  }
  
  .home-carousel {
    height: auto; 
    width: auto; 
    transform: scale(1);
  }  
  
  .home-carousel-large{
    transform: scale(0.7);
  }

}
@media screen and (max-width: 700px) {
  .video {
    height: 600px;
    margin-bottom: 100px;
  }

  .hero-container { 
    margin-top: -600px;
    margin-bottom: 0px;
  }

  .introButtonsCluster {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
  
  .introText h1 {
    font-size: 50px;
  }
  .celebrateBundle{
    justify-content: space-around;
    text-align: center;
  }

  .celebrateMain-title-left{
    text-align: center;
    margin-left: 7.5%;
    margin-right: 7.5%;

  }

  .celebrateMain-title{
    text-align: center;
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .text {
    text-align: center;
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .learnButtonInner {
    margin-left: auto;
    margin-right: auto;
  }

  .celebrateParagraph-right {
    float: none;
  }

  .hero-container {
    margin-bottom: 165px;
  }
  .serve-container{
    margin-left: 10px;
  }
 

  .icon-wrapper {
    min-width: 100px;
    margin-left: -50px;
    text-align: center;
  }

  .serve-container {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
  .serve-container p{
    font-size: 12px;
  }

  

  .subtitle {
    font-size: 20px;
  }

  .symbol {
    font-size: 40px;
  }

  .cmIcon{
    margin: 10px;
  }

  .slick-prev:before {
    font-size: 20px !important;
    margin-left: 5px;
  }

  .slick-next:before {
    font-size: 20px !important;
    margin-left: -5px;
  }
  
  .home-carousel {
    height: auto; 
    width: auto; 
    transform: scale(1);
  }  
  
  .home-carousel-large{
    transform: scale(0.7);
  }
}


@media screen and (max-width: 500px) {
  .serviceText{
    margin-top: -10px;
  }
}