.urlBackground{
    background-color: #F7FAFF;
    height: 100%;
  }

  .events-background{
    padding-top: 200px;
    width: 100%;
    background-color: #808000;
    height: 600px;
    display:inline-block;
}


.heading {
  font-size: 35px;
  color: #f7faff;
  font-family: "comfortaa";
  text-align: center;
}

.context {
  font-size: 18px;
  color: #f7faff;
  font-family: "poppins";
  text-align: center;
}

.eventsMain {
  width: 100%;
  text-align: center;
  padding: 20px;
  /* margin-left: 150px;
  float: left; */
}