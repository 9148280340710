#LifeAfterLifeVolunteerPage{
  background-color: #f7faff;
  padding-top: 50px;
}
.textBundleVolunteer1{
  width: 60%;
  float: left;
  margin-top: 5%;
  text-align: left;
}
.textBundleVolunteer2{
  width: 60%;
  float: right;
  margin-top: 5%;
}
.leaf-img {
  width: 20%;
  float: right;
  margin-top: -20%;

  /* margin: auto; */
  margin-top: 80px;
  margin-left: 50px;
  background-color: #f7faff;

}
.volunteerFAQ{
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  margin-left: 10%;
}
.volunteerAnswers{
  padding: 1em;
  list-style: square;
  text-align: left;
}

.protectImg {
  width: 35%;
  float: left;
  
  /* margin: auto; */
  margin-top: 10px;
 
  background-color: #f7faff;

}
.objective_summaryFinal{
  margin-top: 50px;
  text-align: center;
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  margin-bottom: 5%;
}
.volunteerCheckout:hover{
  cursor: pointer;
  border: 1px solid #F7FAFF;
  color: #F7FAFF;
  background-color: #808000;
  text-decoration: none;

}
.volunteerCheckout{
  text-decoration: none;
  background-color: transparent;
  color: #808000;
  border: 2px solid #808000;
  display: block;
  transition-duration: 0.3s;
  font-family: "comfortaa";
  font-size: 16px;
  border-radius: 25px;
  height: 50px;
  width: 160px;
  text-align: center;
  padding-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: -2%;
}
.objectiveMain{

  background-color: #f7faff;
}
.objectiveText{
  background-color: #f7faff;
}
.importantMain{

  background-color: #f7faff;
}
.objective_summary {
  /* background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  padding: 70px;
  width: 100%;
  margin-bottom: 85px;
  margin-top: -60px; */


}




.volunteerFormBundle {
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #f7faff;

}

.volunteerHeadline {
  font-family: "comfortaa";
  font-size: 35px;
  color: #808000;
  background-color: #f7faff;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
  margin-top: 15px;
  
}

.volunteer-faq-title {
  text-align: left;
  margin-top: 50px;
  font-size: 35px;
  margin-left: 105px;
  color: #808000;
  font-family: "comfortaa";
}

.subsection-title1-collapsible {
  text-align: left;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-size: 25px;
  flex:1;
  margin-left: 50px;
  color: #808000;
  font-family: "comfortaa";
}

.--plus-sign-volunteer{
  
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: 100px;
  display: block;
  transition: .4s transform ease-out;
}

.volunteer-faq-answer{
  margin-left: 185px;
  text-align: left;
}

.volunteer-collapsible {
  margin-top: 2%;
  color: #808000;
  font-family: "Comfortaa";
  background-color: #F7FAFF;
  text-align: center;
}

.subsection-title1 {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 40px;
margin-left: 7%;
  font-size: 25px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
  
}
@media screen and (max-width: 1200px){

  .textBundleVolunteer1{
    width: 65%;
    
  margin-bottom: -10%;
  }
.leaf-img{
  width: 20%;
  margin-top: 5%;
  margin-bottom: -10%;
}

.textBundleVolunteer2{
  width: 65%;
}
.protectImg{
width: 30%;
margin-top: 0%;
}

}
@media screen and (max-width: 992px){
  .textBundleVolunteer1{
    width: 100%;
    text-align:center;
    margin-bottom: -20%;

  }
.leaf-img{
  width: 25%;
  margin-left: 37%;
}

.subsection-title1{
  text-align: center;
}

.textBundleVolunteer2{
  width: 100%;
  text-align:center;
  margin-top: 100px;
  
}
.protectImg{
  margin-top: 30px;
  width: 25%;
  margin-left: 37%;
  margin-bottom: 30px;
}

.volunteer-faq-bundle{
  margin-left: -100px;
}

}
@media screen and (max-width: 768px){
  .textBundleVolunteer1{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
    

  }
  .subsection-title1{
    text-align: center;
    margin-left: 0%;
  
  }
  .objective_summary{
    text-align: center;
    margin-bottom: 0%;
  }
.leaf-img{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;

}
.volunteer-faq-title {
  text-align: center;
}
.textBundleVolunteer2{
  width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
}
.protectImg{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: 0%;
}

}



@media screen and (max-width: 414px){
.leaf-img{
margin-left: 30%;
margin-top: 5%;
margin-bottom: 5%;

}
.volunteer-faq-title {
  text-align: center;
}
}