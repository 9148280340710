#LifeAfterLifeLearnPage{
  background-color: #f7faff;
  margin-top: 50px;
}
.textBundleLearn1{
  width: 60%;
  float: left;
}
.textBundleLearn2{
  width: 60%;
  float: right;
}
.berryBranchPic {
  width: 35%;
  float: right;

  /* margin: auto; */
  margin-top: 80px;
  margin-left: 50px;
  background-color: #f7faff;

}

.yellowBranchPic {
  width: 35%;
  float: left;
  
  /* margin: auto; */
  margin-top: 10px;
 
  background-color: #f7faff;

}
.objectiveMain{

  background-color: #f7faff;
}
.objectiveText{
  background-color: #f7faff;
}
.importantMain{

  background-color: #f7faff;
}
.objective_summary {
  background-color: #f7faff;
  font-size: 22px;
  font-family: "poppins";
  color: #808000;
  border-radius: 5px;
  justify-content: center;
  padding: 70px;
  width: 100%;
  margin-bottom: 85px;
  margin-top: -60px;


}




.learnFormBundle {
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #f7faff;

}

.learnHeadline {
  font-family: "comfortaa";
  font-size: 35px;
  color: #808000;
  background-color: #f7faff;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
  margin-top: 15px;
  
}


.subsection-title {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 40px;
  margin-left: 60px;
  font-size: 35px;
  color: #808000;
  font-family: "comfortaa";
  background-color: #f7faff;
  
}
@media screen and (max-width: 1200px){

  .textBundleLearn1{
    width: 65%;
  }
.berryBranchPic{
  width: 25%;
  margin-top: 20%;
}

.textBundleLearn2{
  width: 65%;
}
.yellowBranchPic{
width: 30%;
margin-top: 20%;
}

}
@media screen and (max-width: 1024px){
  .textBundleLearn1{
    width: 70%;
  }
.berryBranchPic{
  width: 20%;
  margin-top: 20%;
}

.textBundleLearn2{
  width: 70%;
}
.yellowBranchPic{
width: 25%;
margin-top: 20%;
}

}
@media screen and (max-width: 768px){
  .textBundleLearn1{
    width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;

  }
  .subsection-title{
    text-align: center;
    margin-left: -.5%;
  }
  .objective_summary{
    text-align: center;
    margin-bottom: 0%;
  }
.berryBranchPic{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;

}

.textBundleLearn2{
  width: 100%;
    float: none;   
    margin-left: auto;
    margin-right: auto;
}
.yellowBranchPic{
  width: 40%;
  float: none;
margin-left: 30%;
margin-top: -5%;
}
}


@media screen and (max-width: 414px){
#learn_body{
  margin-top: -25%;
}
}